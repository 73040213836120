import React, { CSSProperties } from 'react'

import styled from '@emotion/styled'

import { colors, typography } from '../../theme'

const TextBase = styled.span<{
  themeColor?: keyof typeof colors
  typography?: keyof typeof typography
  textAlign?: CSSProperties['textAlign']
}>`
  ${({ theme, themeColor }) => (themeColor ? `color: ${theme.colors[themeColor]};` : '')}
  ${({ theme, typography }) => (typography ? theme.typography[typography] : '')}
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}
`

// Wraps the TextBase since if we pass a `color` attribute directly to a styled component
// it will show up as an attribute in the DOM.
const Text = ({
  color,
  ...rest
}: Omit<React.ComponentProps<typeof TextBase>, 'themeColor'> & {
  color?: React.ComponentProps<typeof TextBase>['themeColor']
}) => {
  return <TextBase themeColor={color} {...rest} />
}

export default Text
