/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {AcceptPhoneVerificationAttemptRequest} from '@cash-web/protos/squareup/cash/cashvoice/verification/app/v1/app.pb'
import {AcceptPhoneVerificationAttemptResponse} from '@cash-web/protos/squareup/cash/cashvoice/verification/app/v1/app.pb'


export const acceptPhoneVerificationAttempt = (request:AcceptPhoneVerificationAttemptRequest, options?: CashRequestOptions) => post<AcceptPhoneVerificationAttemptRequest, AcceptPhoneVerificationAttemptResponse>("/2.0/cash/accept-phone-verification-attempt", request, {...options, rejectOnError: true })