/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {ExchangeRequest} from '@cash-web/protos/squareup/cash/cryptoinvestflow/service/exchange.pb'
import {ExchangeResponse} from '@cash-web/protos/squareup/cash/cryptoinvestflow/service/exchange.pb'


export const exchange = (request:ExchangeRequest, options?: CashRequestOptions) => post<ExchangeRequest, ExchangeResponse>("/2.0/cash/exchange", request, {...options, rejectOnError: true })