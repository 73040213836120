/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetDiscoveryRequest} from '@cash-web/protos/squareup/cash/marketdata/service.pb'
import {GetDiscoveryResponse} from '@cash-web/protos/squareup/cash/marketdata/service.pb'


export const getDiscovery = (request:GetDiscoveryRequest, options?: CashRequestOptions) => post<GetDiscoveryRequest, GetDiscoveryResponse>("/2.0/cash/get-discovery", request, {...options, rejectOnError: true })