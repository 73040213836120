import * as React from 'react'

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { ArcadeIcon } from '@arcade/web/icons/react'

import { useDebounce } from '../../hooks/useDebounce'
import { truncate } from '../../styles'
import { spacing } from '../../theme'
import LoadingIcon from '../LoadingIcon/LoadingIcon'
import { focusRingStyles, getVariantStyles } from './arcade-button-styles'

export type ButtonSize = 'default' | 'cta' | 'compact'
export type ButtonProminence = 'prominent' | 'standard' | 'subtle'

export type ButtonProps = {
  isDestructive?: boolean
  prominence?: ButtonProminence
  size?: ButtonSize
  Icon?: ArcadeIcon
  disabled?: boolean
  isLoading?: boolean
  loadingDelay?: number
  linesToTruncate?: number
  ariaLabel?: string
  as?: React.ElementType
} & React.ButtonHTMLAttributes<HTMLButtonElement>

type StyleProps = Pick<ButtonProps, 'prominence' | 'size' | 'linesToTruncate' | 'isDestructive'> & {
  functionallyLoading?: boolean
  visuallyLoading?: boolean
  functionallyDisabled?: boolean
  visuallyDisabled?: boolean
}

const ButtonContainer = styled.button<StyleProps>`
  position: relative;
  display: grid;
  background: transparent;
  border: 0;
  width: 100%;
  padding: ${({ theme: { spacing } }) => spacing.empty};
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  ${({ size }) => {
    switch (size) {
      case 'default':
      case 'compact':
        return css`
          width: auto;
        `
      default:
        return
    }
  }}

  ${({ visuallyLoading }) =>
    visuallyLoading &&
    css`
      cursor: default;
    `}

    ${({ visuallyDisabled }) =>
    visuallyDisabled &&
    css`
      cursor: not-allowed;
    `}

    /* focus ring styles */
    ${({ theme }) => focusRingStyles({ theme })}
`

export const StyledButton = styled.span<StyleProps>`
  /* text */
  font-family: inherit;
  text-align: center;
  letter-spacing: 0.01em;

  /* layout */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => `${props.theme.spacing.empty} ${props.theme.spacing.m}`};
  height: 3rem;
  max-height: 3rem;
  box-sizing: border-box;

  /* typography */
  ${props => props.theme.typography.buttonText}

  /* motion */
  ${props => props.theme.motion.standardTransition}
  transition-property: transform, background, color, opacity;

  border-radius: 1.5rem;
  cursor: pointer;

  ${({ linesToTruncate }) =>
    linesToTruncate &&
    linesToTruncate > 1 &&
    css`
      ${truncate(linesToTruncate)}
      height: auto;
      max-height: initial;
      border-radius: 2rem;
      padding: ${spacing.xxs} ${spacing.default};
    `}

  /* apply variant styles */
  ${({ functionallyDisabled, visuallyDisabled, theme, prominence, size, isDestructive }) =>
    getVariantStyles({ functionallyDisabled, visuallyDisabled, theme, prominence, size, isDestructive })}
`

const StyledChildren = styled.span<{ hasIcon: boolean }>`
  ${({ hasIcon }) => hasIcon && `padding-left: ${spacing.xs};`}
`

// Keep button Buttoncontainer to avoid unclickable edges when using this component as an `a` tag caused by `scale` property
const ArcadeButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      ariaLabel,
      children,
      prominence,
      disabled,
      isLoading,
      loadingDelay = 0,
      size = 'default',
      linesToTruncate,
      Icon,
      isDestructive,
    },
    ref
  ) => {
    const functionallyLoading = isLoading
    const visuallyLoading = useDebounce(isLoading, isLoading ? loadingDelay : 0)

    const functionallyDisabled = disabled || isLoading
    const visuallyDisabled = disabled

    const styleProps: StyleProps = {
      prominence,
      size,
      functionallyLoading,
      visuallyLoading,
      functionallyDisabled,
      visuallyDisabled,
      linesToTruncate,
      isDestructive,
    }
    return (
      <ButtonContainer ref={ref} {...styleProps} disabled={functionallyDisabled} aria-label={ariaLabel || 'button'}>
        <StyledButton {...styleProps}>
          {!visuallyLoading && Icon ? <Icon data-testid="arcade-button-icon" size={24} /> : null}
          {visuallyLoading ? (
            <LoadingIcon size="1.5rem" color="current" />
          ) : (
            <StyledChildren hasIcon={!!Icon}>{children}</StyledChildren>
          )}
        </StyledButton>
      </ButtonContainer>
    )
  }
)

export default ArcadeButton
