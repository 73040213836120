/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetInvestmentEntityDetailRequest} from '@cash-web/protos/squareup/cash/marketdata/service.pb'
import {GetInvestmentEntityDetailResponse} from '@cash-web/protos/squareup/cash/marketdata/service.pb'


export const getInvestmentEntityDetail = (request:GetInvestmentEntityDetailRequest, options?: CashRequestOptions) => post<GetInvestmentEntityDetailRequest, GetInvestmentEntityDetailResponse>("/2.0/cash/get-investment-entity-detail", request, {...options, rejectOnError: true })