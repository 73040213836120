import { semantic } from '@arcade/web'
import * as tokens from '@cash-design-system/tokens'

import bitcoinColors from './bitcoinColors'
import taxesColors from './taxesColors'

// please do not use from this palette directly, instead prefer the semantic colors below
export const palette = {
  black: tokens.ColorPaletteBlack,
  gray20: tokens.ColorPaletteGray20,
  gray40: tokens.ColorPaletteGray40,
  gray60: tokens.ColorPaletteGray60,
  gray75: tokens.ColorPaletteGray75,
  gray85: tokens.ColorPaletteGray85,
  gray90: tokens.ColorPaletteGray90,
  gray96: tokens.ColorPaletteGray96,
  gray98: tokens.ColorPaletteGray98,
  white: tokens.ColorPaletteWhite,
  cobalt: tokens.ColorPaletteCobalt,
  cobalt5: tokens.ColorPaletteCobalt5,
  cobalt10: tokens.ColorPaletteCobalt10,
  cobalt20: tokens.ColorPaletteCobalt20,
  cobalt30: tokens.ColorPaletteCobalt30,
  cobalt40: tokens.ColorPaletteCobalt40,
  cobalt60: tokens.ColorPaletteCobalt60,
  behindCobalt: tokens.ColorPaletteBehindCobalt,
  bgGreen: tokens.ColorPaletteBgGreen,
  lightModeGreen: tokens.ColorPaletteLightModeGreen,
  darkModeGreen: tokens.ColorPaletteDarkModeGreen,
  lightModeBannerGreen: '#F9FFFB',
  darkModeBannerGreen: '#25452C',
  disabledGreen: '#80eaa7',
  tooltipGreen: '#4CD964',
  activeGreen: '#00a83e',
  onGreen: tokens.ColorPaletteOnGreen,
  lightModeAmber: tokens.ColorPaletteAmber,
  darkModeAmber: '#E0490C',
  ocean: tokens.ColorPaletteOcean,
  pink: tokens.ColorPalettePink,
  purple: tokens.ColorPalettePurple,
  red: tokens.ColorPaletteRed,
  darkRed: '#D7040E',
  royal: tokens.ColorPaletteRoyal,
  scarlet: tokens.ColorPaletteScarlet,
  sky: tokens.ColorPaletteSky,
  steel: tokens.ColorPaletteSteel,
  sunshine: tokens.ColorPaletteSunshine,
  turquoise: tokens.ColorPaletteTurquoise,
  bitcoinBlue: tokens.ColorPaletteBitcoinBlue,
  bgBitcoinBlue: tokens.ColorPaletteBgBitcoinBlue,
  investingPurpleLight: tokens.ColorPaletteInvestingPurpleLight,
} as const

// These are the semantic colors
export const colors = {
  primary: {
    light: palette.lightModeGreen,
    dark: palette.darkModeGreen,
  },
  tint: {
    light: tokens.ColorTintGreenLight,
    dark: tokens.ColorTintGreenDark,
  },
  green: {
    light: tokens.ColorTintGreenLight,
    dark: tokens.ColorTintGreenDark,
  },
  onGreen: {
    light: palette.onGreen,
    dark: palette.onGreen,
  },
  onGreenBackground: {
    light: palette.lightModeBannerGreen,
    dark: palette.darkModeBannerGreen,
  },
  /**
   * A more semantic alias for the "semantic" green.
   */
  success: {
    light: 'green',
    dark: 'green',
  },
  active: {
    light: palette.activeGreen,
    dark: palette.activeGreen,
  },
  disabled: {
    light: palette.disabledGreen,
    dark: palette.disabledGreen,
  },
  tooltip: {
    light: palette.tooltipGreen,
    dark: palette.tooltipGreen,
  },
  /**
   * The tint color for verification flows.
   */
  verificationTint: {
    light: tokens.ColorTintVerificationLight,
    dark: tokens.ColorTintVerificationDark,
  },
  error: {
    light: palette.red,
    dark: palette.red,
  },
  errorHover: {
    light: '#DF3A42',
    dark: '#DF3A42',
  },
  errorPressed: {
    light: palette.darkRed,
    dark: palette.darkRed,
  },
  warning: {
    light: palette.lightModeAmber,
    dark: palette.darkModeAmber,
  },
  /**
   * The color for content behind the main background.
   */
  behindBackground: {
    light: tokens.ColorBackgroundBehindLight,
    dark: tokens.ColorBackgroundBehindDark,
  },
  cardTabBackground: {
    light: tokens.ColorBackgroundCardTabLight,
    dark: tokens.ColorBackgroundCardTabDark,
  },
  /**
   * The color for the main background of an interface.
   */
  background: {
    light: tokens.ColorBackgroundMainLight,
    dark: tokens.ColorBackgroundMainDark,
  },
  /**
   * The color for content layered on top of the main background.
   */
  secondaryBackground: {
    light: tokens.ColorBackgroundSecondaryLight,
    dark: tokens.ColorBackgroundSecondaryDark,
  },
  /**
   * The color for content layered on top of secondary backgrounds.
   */
  tertiaryBackground: {
    light: tokens.ColorBackgroundTertiaryLight,
    dark: tokens.ColorBackgroundTertiaryDark,
  },
  /**
   * The color for the background of a view while content is loading (e.g. avatars).
   */
  placeholderBackground: {
    light: tokens.ColorBackgroundPlaceholderLight,
    dark: tokens.ColorBackgroundPlaceholderDark,
  },
  /**
   * The color for content presented modally over existing content.
   */
  elevatedBackground: {
    light: tokens.ColorBackgroundElevatedLight,
    dark: tokens.ColorBackgroundElevatedDark,
  },
  /**
   * The color for content layered on top of modally presented content.
   */
  secondaryElevatedBackground: {
    light: tokens.ColorBackgroundElevatedSecondaryLight,
    dark: tokens.ColorBackgroundElevatedSecondaryDark,
  },
  /**
   * The background color for transparent images.
   */
  transparentImageBackground: {
    light: palette.gray96,
    dark: 'secondaryBackground',
  },
  /**
   * The color for the main background of the web interface.
   */
  behindCardBackground: {
    light: palette.gray96,
    dark: palette.cobalt,
  },
  /**
   * The color for the background of content cards of the web interface.
   */
  cardBackground: {
    light: palette.white,
    dark: palette.cobalt5,
  },
  /**
   * The color for text that contains primary content.
   */
  label: {
    light: tokens.ColorLabelDefaultLight,
    dark: tokens.ColorLabelDefaultDark,
  },
  /**
   * The color for text that contains secondary content.
   */
  secondaryLabel: {
    light: tokens.ColorLabelSecondaryLight,
    dark: tokens.ColorLabelSecondaryDark,
  },
  /**
   * The color for text that contains tertiary content.
   */
  tertiaryLabel: {
    light: tokens.ColorLabelTertiaryLight,
    dark: tokens.ColorLabelTertiaryDark,
  },
  /**
   * The color for placeholder text in text fields.
   */
  placeholderLabel: {
    light: tokens.ColorLabelPlaceholderLight,
    dark: tokens.ColorLabelPlaceholderDark,
  },
  /**
   * The color for text that is disabled.
   */
  disabledLabel: {
    light: tokens.ColorLabelDisabledLight,
    dark: tokens.ColorLabelDisabledDark,
  },
  /**
   * Text color used for general copy and headers in a marketing context.
   */
  marketingLabel: {
    light: palette.black,
    dark: palette.white,
  },
  /**
   * The color for the input cursor in a text field.
   */
  cursor: {
    light: 'primary',
    dark: 'primary',
  },
  /**
   * The background color of the clear button in a text field.
   */
  clearButtonTint: {
    light: tokens.ColorInputClearButtonTintLight,
    dark: tokens.ColorInputClearButtonTintDark,
  },
  /**
   * The background color of a Mooncake primary button.
   */
  primaryButtonBackground: {
    light: '#00D64F',
    dark: '#00BD46',
  },
  /**
   * The background color of a Mooncake primary button when being hovered over.
   */
  primaryButtonHover: {
    light: '#00C147',
    dark: '#009738',
  },
  /**
   * The background color of a Mooncake primary button when being clicked.
   */
  primaryButtonActive: {
    light: '#00A93E',
    dark: '#008431',
  },
  /**
   * The foreground color of a Mooncake primary button.
   */
  primaryButtonTint: {
    light: tokens.ColorButtonPrimaryTintLight,
    dark: tokens.ColorButtonPrimaryTintDark,
  },
  /**
   * The background color of a Mooncake secondary button.
   */
  secondaryButtonBackground: {
    light: '#E8E8E8',
    dark: '#3B3C3E',
  },
  /**
   * The background color of a Mooncake secondary button when being hovered over.
   */
  secondaryButtonHover: {
    light: '#DCDCDC',
    dark: '#353638',
  },
  /**
   * The background color of a Mooncake secondary button when being clicked.
   */
  secondaryButtonActive: {
    light: '#CECECE',
    dark: '#2F3032',
  },
  /**
   * The foreground color of a Mooncake secondary button.
   */
  secondaryButtonTint: {
    light: tokens.ColorButtonSecondaryTintLight,
    dark: tokens.ColorButtonSecondaryTintDark,
  },
  /**
   * The foreground color of a Mooncake tertiary button.
   */
  tertiaryButtonTint: {
    light: 'primaryButtonBackground',
    dark: 'primaryButtonBackground',
  },
  /**
   * The selected state border color of a Mooncake outline button.
   */
  outlineButtonSelectedBorder: {
    light: tokens.ColorButtonOutlineSelectedBorderLight,
    dark: tokens.ColorButtonOutlineSelectedBorderDark,
  },
  /**
   * The background color of form fields.
   */
  formFieldBackground: {
    light: tokens.ColorPaletteGray96,
    dark: tokens.ColorPaletteCobalt20,
  },
  /**
   * The background color of form fields when disabled.
   */
  formFieldBackgroundDisabled: {
    light: tokens.ColorPaletteGray98,
    dark: tokens.ColorPaletteCobalt10,
  },
  /**
   * The foreground color of a large segmented control.
   */
  segmentedControlForeground: {
    light: tokens.ColorPaletteWhite,
    dark: tokens.ColorPaletteCobalt60,
  },
  /**
   * The background color of a large segmented control.
   */
  segmentedControlBackground: {
    light: tokens.ColorPaletteGray90,
    dark: tokens.ColorPaletteCobalt30,
  },
  /**
   * The foreground color of the selected indicator dot in a page control.
   */
  pageControlSelected: {
    light: tokens.ColorButtonPageControlSelectedLight,
    dark: tokens.ColorButtonPageControlSelectedDark,
  },
  /**
   * The foreground color of the unselected indicator dots in a page control.
   */
  pageControlUnselected: {
    light: tokens.ColorButtonPageControlUnselectedLight,
    dark: tokens.ColorButtonPageControlUnselectedDark,
  },
  icon: {
    light: tokens.ColorIconDefaultLight,
    dark: tokens.ColorIconDefaultDark,
  },
  secondaryIcon: {
    light: tokens.ColorIconSecondaryLight,
    dark: tokens.ColorIconSecondaryDark,
  },
  tertiaryIcon: {
    light: tokens.ColorIconTertiaryLight,
    dark: tokens.ColorIconTertiaryDark,
  },
  placeholderIcon: {
    light: tokens.ColorIconPlaceholderLight,
    dark: tokens.ColorIconPlaceholderDark,
  },
  disabledIcon: {
    light: tokens.ColorIconDisabledLight,
    dark: tokens.ColorIconDisabledDark,
  },
  /**
   * The color used for chevron arrow icons.
   */
  chevron: {
    light: tokens.ColorIconChevronLight,
    dark: tokens.ColorIconChevronDark,
  },
  /**
   * The color used for drag handles on sheets.
   */
  dragHandle: {
    light: tokens.ColorIconDragHandleLight,
    dark: tokens.ColorIconDragHandleDark,
  },
  /**
   * The color used for separators that should blend in with their background.
   */
  hairline: {
    light: tokens.ColorPaletteGray90,
    dark: tokens.ColorPaletteCobalt20,
  },
  /**
   * The color used for outlined content and separators that should be more prominent than their background.
   */
  outline: {
    light: tokens.ColorOutlineLight,
    dark: tokens.ColorOutlineDark,
  },
  unselectedPasscodeDot: {
    light: tokens.ColorUnselectedPasscodeDotLight,
    dark: tokens.ColorUnselectedPasscodeDotDark,
  },
  /**
   * The color used for foreground content of the Today View widget.
   */
  widgetForeground: {
    light: tokens.ColorWidgetForegroundLight,
    dark: tokens.ColorWidgetForegroundDark,
  },
  /**
   * The color used for the foreground of a custom numeric keyboard.
   */
  keyboard: {
    light: tokens.ColorKeyboardLight,
    dark: tokens.ColorKeyboardDark,
  },
  cardCustomizationStroke: {
    light: tokens.ColorCardCustomizationStrokeLight,
    dark: tokens.ColorCardCustomizationStrokeDark,
  },
  /**
   * The light avatar color candidate used for the foreground in the getContrastingForegroundColor function.
   */
  lightForegroundAvatarColor: {
    light: tokens.ColorButtonPrimaryTintLight,
    dark: tokens.ColorButtonPrimaryTintLight,
  },
  /**
   * The dark avatar color candidate used for the foreground in the getContrastingForegroundColor function.
   */
  darkForegroundAvatarColor: {
    light: tokens.ColorBackgroundPlaceholderDark,
    dark: tokens.ColorBackgroundPlaceholderDark,
  },
  /**
   * The color used for the background of the main payment pad.
   */
  paymentPadBackground: {
    light: tokens.ColorPaymentPadBackgroundLight,
    dark: tokens.ColorPaymentPadBackgroundDark,
  },
  /**
   * The color used for buttons on the main payment pad.
   */
  paymentPadButtonBackground: {
    light: tokens.ColorPaymentPadButtonBackgroundLight,
    dark: tokens.ColorPaymentPadButtonBackgroundDark,
  },
  /**
   * The color used for ghosted characters on the main payment pad.
   */
  paymentPadGhostedTextColor: {
    light: tokens.ColorPaymentPadGhostedTextLight,
    dark: tokens.ColorPaymentPadGhostedTextDark,
  },
  /**
   * The color used for the foreground of the custom numeric keyboard on the main payment pad.
   */
  paymentPadKeyboard: {
    light: tokens.ColorPaymentPadKeyboardLight,
    dark: tokens.ColorPaymentPadKeyboardDark,
  },
  bitcoinBlue: {
    light: palette.bitcoinBlue,
    dark: palette.bitcoinBlue,
  },
  /**
   * The color used for the background of the bitcoin main payment pad.
   */
  bitcoinPaymentPadBackground: {
    light: tokens.ColorPaymentPadBitcoinBackgroundLight,
    dark: tokens.ColorPaymentPadBitcoinBackgroundDark,
  },
  /**
   * The color used for buttons on the bitcoin main payment pad.
   */
  bitcoinPaymentPadButtonBackground: {
    light: tokens.ColorPaymentPadBitcoinButtonBackgroundLight,
    dark: tokens.ColorPaymentPadBitcoinButtonBackgroundDark,
  },
  scrollHint: {
    light: tokens.ColorActivityScrollHintLight,
    dark: tokens.ColorActivityScrollHintDark,
  },
  investing: {
    light: tokens.ColorInvestingPurpleLight,
    dark: tokens.ColorInvestingPurpleDark,
  },
  bannerTextSuccess: {
    light: '#018206',
    dark: '#00D64F',
  },
  bannerBackgroundSuccess: {
    light: palette.lightModeBannerGreen,
    dark: palette.darkModeBannerGreen,
  },
  bannerButtonSuccess: {
    light: '#D7F8E3',
    dark: '#2C5633',
  },
  bannerButtonSuccessPressed: {
    light: '#C7EDD9',
    dark: '#306134',
  },
  bannerHairlineSuccess: {
    light: '#D7F8E3',
    dark: '#2C5633',
  },
  bannerTextError: {
    light: '#D7040E',
    dark: '#FF545C',
  },
  bannerBackgroundError: {
    light: '#FFF8F8',
    dark: '#3A2323',
  },
  bannerButtonError: {
    light: '#D7F8E3',
    dark: '#2C5633',
  },
  bannerButtonErrorPressed: {
    light: '#C7EDD9',
    dark: '#306134',
  },
  bannerHairlineError: {
    light: '#D7F8E3',
    dark: '#2C5633',
  },
  bannerTextWarn: {
    light: '#A06503',
    dark: '#CBA624',
  },
  bannerBackgroundWarn: {
    light: '#FFFCE5',
    dark: '#292500',
  },
  bannerTextInfo: {
    light: '#666666',
    dark: '#a2a3a3',
  },
  bannerBackgroundInfo: {
    light: '#E8E8E8',
    dark: '#3B3C3E',
  },
  errorPageBackground: {
    light: palette.white,
    dark: palette.cobalt,
  },
  /**
   * The font color for the refund amount
   */
  refundAmount: {
    light: 'primary',
    dark: 'primary',
  },
  taxesReviewAndFileBannerBackground: {
    light: 'primaryBannerBackground',
    dark: 'bannerBackgroundSuccess',
  },
  taxesReviewAndFileBannerText: {
    light: palette.white,
    dark: 'bannerTextSuccess',
  },
  taxesReviewAndFileBannerButtonTint: {
    light: 'primaryBannerBackground',
    dark: 'bannerBackgroundSuccess',
  },
  taxesReviewAndFileBannerButtonBackground: {
    light: palette.white,
    dark: palette.white,
  },
  /**
   * The color for text in the mobile login screen
   */
  taxesLoginMobileColor: {
    light: palette.gray96,
    dark: palette.cobalt5,
  },
  /**
   * The color for text in the taxes timeline bubble
   */
  taxesTimelineBubbleText: {
    light: 'primary',
    dark: palette.white,
  },
  /**
   * The color for outline in the taxes timeline bubble
   */
  taxesTimelineBubbleOutline: {
    light: 'primary',
    dark: 'primary',
  },
  primaryBannerText: {
    light: 'primaryButtonTint',
    dark: 'primaryButtonTint',
  },
  primaryBannerBackground: {
    light: palette.bgGreen,
    dark: 'bannerBackgroundSuccess',
  },
  /**
   * The color for link text.
   */
  link: {
    light: 'primaryButtonBackground',
    dark: 'primaryButtonBackground',
  },
  /**
   * The color for text that contains secondary content when being hovered over.
   */
  textButtonLabelHover: {
    light: 'label',
    dark: 'tertiaryLabel',
  },
  /**
   * The color for general body text.
   */
  body: {
    light: palette.gray40,
    dark: palette.cobalt60,
  },
  /**
   * progress bar fill color
   */
  progressBarFill: {
    light: 'primary',
    dark: 'primary',
  },
  /**
   * progress bar background color
   */
  progressBarBackground: {
    light: tokens.ColorPaletteGray96,
    dark: tokens.ColorPaletteCobalt20,
  },
  /**
   * Review and File inactive progress bar background color.
   */
  searchBackground: {
    light: palette.gray96,
    dark: palette.cobalt5,
  },
  /**
   * The color for error link text.
   */
  errorLink: {
    light: palette.scarlet,
    dark: palette.scarlet,
  },
  /**
   * The hover color for error link text.
   */
  errorLinkHover: {
    light: '#FC6A6A',
    dark: '#FC6A6A',
  },
  /**
   * Color used for waiting tint.
   */
  waitingTint: {
    light: palette.lightModeGreen,
    dark: palette.darkModeGreen,
  },
  // TODO: update links to use arcade tokens (bianca/asif)
  /**
   * The color for blue link text.
   */
  blueLink: {
    light: '#0000ff',
    dark: '#8EB5FF',
  },
  /**
   * The color for blue link text that has been visited.
   */
  blueLinkVisited: {
    light: '#660199',
    dark: '#AC8EFF',
  },
  /**
   * The color for the background of navigation items when being clicked.
   */
  navigationItemActiveBackground: {
    light: 'bannerButtonSuccess',
    dark: tokens.ColorPaletteCobalt20,
  },
  /**
   * The color for the text of navigation items when being clicked.
   */
  navigationItemActiveText: {
    light: palette.activeGreen,
    dark: palette.white,
  },
  /**
   * The color for the outline between nested cards.
   */
  nestedCardOutline: {
    light: tokens.ColorPaletteGray96,
    dark: tokens.ColorPaletteCobalt20,
  },
  /**
   * The loading spinner color for login
   */
  loginSpinner: {
    light: palette.white,
    dark: palette.darkModeGreen,
  },
  /**
   * The background color for login.
   */
  loginBackground: {
    light: tokens.ColorTintGreenLight,
    dark: tokens.ColorBackgroundMainDark,
  },
  /**
   * The color for the background of clickable content like nested cards or rows within a card when being hovered over.
   */
  clickableContentBackgroundHover: {
    light: tokens.ColorPaletteGray96,
    dark: tokens.ColorPaletteCobalt10,
  },
  /**
   * The color for the outline between nested cards when being hovered over.
   */
  nestedCardOutlineHover: {
    light: tokens.ColorPaletteGray90,
    dark: tokens.ColorPaletteCobalt20,
  },
  /**
   * The color for the outline between nested cards when being clicked.
   */
  nestedCardOutlineActive: {
    light: tokens.ColorPaletteGray85,
    dark: tokens.ColorPaletteCobalt30,
  },
  /**
   * The color for the background of content rows when being clicked.
   */
  rowBackgroundActive: {
    light: tokens.ColorPaletteGray90,
    dark: tokens.ColorPaletteCobalt20,
  },
  /**
   * The color for the background of receipt footers.
   */
  receiptFooterBackground: {
    light: tokens.ColorPaletteGray96,
    dark: tokens.ColorPaletteCobalt10,
  },
  /**
   * The color for the outline of checkboxes and radio buttons.
   */
  inputOutline: {
    light: tokens.ColorPaletteGray85,
    dark: tokens.ColorPaletteCobalt40,
  },
  /**
   * The color for the outline of radio buttons when nested within a gray card.
   */
  withinGrayCardInputOutline: {
    light: '#949494',
    dark: tokens.ColorPaletteCobalt40,
  },
  /**
   * The color for the background of tertiary navigation items when being clicked or selected.
   */
  tertiaryNavigationItemBackgroundActive: {
    light: tokens.ColorPaletteGray96,
    dark: tokens.ColorPaletteCobalt20,
  },
  /**
   * The color for the background of tertiary navigation items when being hovered over.
   */
  tertiaryNavigationItemBackgroundHover: {
    light: tokens.ColorPaletteGray98,
    dark: tokens.ColorPaletteCobalt10,
  },
  /**
   * The color of the split payment Icon's border and the trident itself being used in checkout in lending locations.
   */
  mutedIcon: {
    light: palette.white,
    dark: palette.cobalt,
  },
  /**
   * The color for selected checkboxes
   */
  checkboxSelected: {
    light: 'primary',
    dark: 'primary',
  },
  /**
   * The color for the taxes logo icon
   */
  taxesLogoIcon: {
    light: 'primary',
    dark: 'primary',
  },
  /**
   * The color for the inner part of the taxes logo icon
   */
  taxesLogoInnerIcon: {
    light: palette.white,
    dark: palette.white,
  },
  /**
   * The color for the focus border of inputs
   */
  inputFocusBorder: {
    light: 'primary',
    dark: 'primary',
  },
  /**
   * The color for large icons that are typically at the top of the page
   */
  headerIconBackground: {
    light: 'primary',
    dark: 'primary',
  },
  red: {
    light: tokens.ColorTintGreenLight,
    dark: tokens.ColorTintGreenDark,
  },
  iconProminent: {
    light: semantic.iconProminent.light,
    dark: semantic.iconProminent.dark,
  },
  iconStandard: {
    light: semantic.iconStandard.light,
    dark: semantic.iconStandard.dark,
  },
  iconSubtle: {
    light: semantic.iconSubtle.light,
    dark: semantic.iconSubtle.dark,
  },
  iconExtraSubtle: {
    light: semantic.iconExtraSubtle.light,
    dark: semantic.iconExtraSubtle.dark,
  },
  iconInverse: {
    light: semantic.iconInverse.light,
    dark: semantic.iconInverse.dark,
  },
  iconDisabled: {
    light: semantic.iconDisabled.light,
    dark: semantic.iconDisabled.dark,
  },
  iconDanger: {
    light: semantic.iconDanger.light,
    dark: semantic.iconDanger.dark,
  },
  iconWarning: {
    light: semantic.iconWarning.light,
    dark: semantic.iconWarning.dark,
  },
  iconSuccess: {
    light: semantic.iconSuccess.light,
    dark: semantic.iconSuccess.dark,
  },
  iconInfo: {
    light: semantic.iconInfo.light,
    dark: semantic.iconInfo.dark,
  },
  backgroundSubtle: {
    light: semantic.backgroundSubtle.light,
    dark: semantic.backgroundSubtle.dark,
  },
  textStandard: {
    light: semantic.textStandard.light,
    dark: semantic.textStandard.dark,
  },
  textSubtle: {
    light: semantic.textSubtle.light,
    dark: semantic.textSubtle.dark,
  },
  textSuccess: {
    light: semantic.textSuccess.light,
    dark: semantic.textSuccess.dark,
  },
  textWarning: {
    light: semantic.textWarning.light,
    dark: semantic.textWarning.dark,
  },
  textDanger: {
    light: semantic.textDanger.light,
    dark: semantic.textDanger.dark,
  },
  /**
   * The primary color used for chart components (e.g. bars, lines).
   */
  primaryChart: {
    light: 'primary',
    dark: 'primary',
  },
  /**
   * The secondary color used for chart components (e.g. bars, lines).
   */
  secondaryChart: {
    light: 'secondaryLabel',
    dark: 'secondaryLabel',
  },
  toggleOffButton: {
    light: palette.gray75,
    dark: palette.cobalt30,
  },
  toggleOffButtonHover: {
    light: palette.gray60,
    dark: palette.cobalt20,
  },
  toggleOffButtonActive: {
    light: palette.gray40,
    dark: palette.cobalt10,
  },
  tooltipFill: {
    light: palette.white,
    dark: palette.white,
  },
  tooltipMessageStandard: {
    light: palette.gray20,
    dark: palette.cobalt40,
  },
  upsellBannerText: {
    light: '#00b843',
    dark: palette.white,
  },
  upsellBannerBackground: {
    light: '#D7F8E3',
    dark: tokens.ColorPaletteCobalt20,
  },
  giftCardText: {
    light: palette.white,
    dark: palette.white,
  },
  eyebrowHeader: {
    light: palette.ocean,
    dark: palette.ocean,
  },
  paycheckDeduction: {
    light: palette.gray85,
    dark: palette.gray85,
  },
} as const

export const taxesColorsTheme = {
  ...colors,
  ...taxesColors,
} as ThemeColorsOverride

export const bitcoinColorsTheme = {
  ...colors,
  ...bitcoinColors,
} as ThemeColorsOverride

export type PaletteColor = keyof typeof palette
export type Colors = keyof typeof colors
export type ThemeColors = { [key in Colors]: (typeof colors)[key]['light'] | (typeof colors)[key]['dark'] }

// TODO string type should be palette type
export type ThemeColorsOverride = { [key in Colors]: { light: string; dark: string } }

export const themeColorExists = (color: Colors) => colors[color] != null
export const getPaletteColorName = (color: string) => {
  return Object.keys(palette).find((name: string) => {
    const paletteName = name as PaletteColor
    return palette[paletteName] === color
  })
}
export const getThemeColor = (
  themeColors: Partial<ThemeColorsOverride>,
  isDark: boolean,
  key: Colors,
  stack: string[] = []
): string => {
  if (stack.includes(key)) {
    /* eslint-disable-next-line */
    console.warn(`circular color reference [isDark:${isDark}]: [${[...stack, key].join('->')}]`)
    return key
  }

  const semanticColor = themeColors[key]

  const color = (isDark ? semanticColor?.dark : semanticColor?.light) as Colors
  if (themeColorExists(color)) {
    return getThemeColor(themeColors, isDark, color, [...stack, key])
  }
  return color
}

export const getThemeColors = ({
  themeColors,
  isDark,
}: {
  themeColors: Partial<ThemeColorsOverride>
  isDark: boolean
}): ThemeColors => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Object.keys(themeColors).reduce((set: any, key: string) => {
    const color = key as Colors
    set[color] = getThemeColor(themeColors, isDark, color)
    return set
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as any)
}
export type Theme = { colors: ThemeColors; darkMode: boolean }

type ThemeArgs = { theme: Theme } | Theme
export type ThemeVariant = 'default' | 'taxes' | 'bitcoin'

export const getColor = (color: Colors) => (args: ThemeArgs) => {
  if ('colors' in args) {
    return args.colors[color]
  } else {
    return args.theme.colors[color]
  }
}

export const addOpacity = (color: string, opacity: number) => {
  return color + Math.round(255 * opacity).toString(16)
}

export const getColorsByThemeVariant = ({
  themeVariant,
  darkMode,
}: {
  themeVariant: ThemeVariant
  darkMode: boolean
}) => {
  let themeColors: ThemeColorsOverride

  switch (themeVariant) {
    case 'bitcoin':
      themeColors = bitcoinColorsTheme
      break
    case 'taxes':
      themeColors = taxesColorsTheme
      break
    case 'default':
      themeColors = colors
      break
    default:
      themeColors = colors
      break
  }
  return getThemeColors({ themeColors, isDark: darkMode })
}

export default colors
