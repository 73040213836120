/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {RejectPhoneVerificationAttemptRequest} from '@cash-web/protos/squareup/cash/cashvoice/verification/app/v1/app.pb'
import {RejectPhoneVerificationAttemptResponse} from '@cash-web/protos/squareup/cash/cashvoice/verification/app/v1/app.pb'


export const rejectPhoneVerificationAttempt = (request:RejectPhoneVerificationAttemptRequest, options?: CashRequestOptions) => post<RejectPhoneVerificationAttemptRequest, RejectPhoneVerificationAttemptResponse>("/2.0/cash/reject-phone-verification-attempt", request, {...options, rejectOnError: true })