import { component as arcadeComponentColors, semantic as arcadeSemanticColors } from '@arcade/web'

const arcadeColors = {
  ...arcadeSemanticColors,
  ...arcadeComponentColors,
}

export type ArcadeThemeVariant = 'default' | 'taxes' | 'bitcoin'
export type ArcadeColors = keyof typeof arcadeColors
export type ThemeColors = {
  [key in ArcadeColors]: (typeof arcadeColors)[key]['light'] | (typeof arcadeColors)[key]['dark']
}

export type ArcadeThemeColors = {
  semantic: { [key in keyof typeof arcadeSemanticColors]: string }
  component: { [key in keyof typeof arcadeComponentColors]: string }
}

export type ArcadeThemeColorsOverride = { [key in ArcadeColors]: { light: string; dark: string } }
export const themeColorExists = (color: ArcadeColors) => arcadeColors[color] != null

export const getArcadeColorsByThemeVariant = ({
  themeVariant,
  darkMode,
}: {
  themeVariant: ArcadeThemeVariant
  darkMode: boolean
}): ArcadeThemeColors => {
  let themeColors: ArcadeThemeColorsOverride

  switch (themeVariant) {
    default:
      themeColors = arcadeColors
      break
  }

  return getArcadeThemeColors({ themeColors, isDark: darkMode })
}

export const getArcadeThemeColors = ({
  themeColors,
  isDark,
}: {
  themeColors: Partial<ArcadeThemeColorsOverride>
  isDark: boolean
}): ArcadeThemeColors => {
  const semantic = Object.keys(arcadeSemanticColors).reduce((set, key) => {
    set[key as ArcadeColors] = getArcadeThemeColor(themeColors, isDark, key as ArcadeColors)
    return set
  }, {} as ThemeColors)

  const component = Object.keys(arcadeComponentColors).reduce((set, key) => {
    set[key as ArcadeColors] = getArcadeThemeColor(themeColors, isDark, key as ArcadeColors)
    return set
  }, {} as ThemeColors)

  return {
    semantic,
    component,
  }
}

export const getArcadeThemeColor = (
  themeColors: Partial<ArcadeThemeColorsOverride>,
  isDark: boolean,
  key: ArcadeColors,
  stack: string[] = []
): string => {
  if (stack.includes(key)) {
    /* eslint-disable-next-line */
    console.warn(`circular color reference [isDark:${isDark}]: [${[...stack, key].join('->')}]`)
    return key
  }

  const semanticColor = themeColors[key]

  const color = (isDark ? semanticColor?.dark : semanticColor?.light) as ArcadeColors
  if (themeColorExists(color)) {
    return getArcadeThemeColor(themeColors, isDark, color, [...stack, key])
  }

  return color
}

export default arcadeColors
