import { useEffect, useRef } from 'react'

import { WebViewViewApp } from '@squareup/customer-data-events-web'

import { useLogCashWebCdfEvent } from './useLogCashWebCdfEvent'
import { useLogEventstream } from './useLogEventstream'

export function useWebViewViewApp() {
  const logCdfEvent = useLogCashWebCdfEvent({ waitForProfile: false })

  // NOTE: Please do not remove this yet. We are debugging something - August 6, 2024
  const logEs2Event = useLogEventstream()
  const logEs2EventRef = useRef(logEs2Event)

  useEffect(() => {
    logCdfEvent(WebViewViewApp())
    logEs2EventRef.current({ action: 'WebViewViewApp', description: 'dependencies: [logCdfEvent]' })
  }, [logCdfEvent])

  useEffect(() => {
    logEs2EventRef.current({ action: 'WebViewViewApp', description: 'dependencies: []' })
  }, [])
}
