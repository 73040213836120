/**********************************************************************
 * WARNING: This file was autogenerated by util-protos generateFiles.js
 * Do not manually edit!
 ***********************************************************************/

import { post, CashRequestOptions } from '@cash-web/shared-util-fetch'
import {GetPhoneVerificationAttemptRequest} from '@cash-web/protos/squareup/cash/cashvoice/verification/app/v1/app.pb'
import {GetPhoneVerificationAttemptResponse} from '@cash-web/protos/squareup/cash/cashvoice/verification/app/v1/app.pb'


export const getPhoneVerificationAttempt = (request:GetPhoneVerificationAttemptRequest, options?: CashRequestOptions) => post<GetPhoneVerificationAttemptRequest, GetPhoneVerificationAttemptResponse>("/2.0/cash/get-phone-verification-attempt", request, {...options, rejectOnError: true })